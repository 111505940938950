<template>
  <div class="fromProcess-relav-form" v-show="isLoad">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">

          <el-form-item v-if="this.form.id !== undefined" label="表单类型">
            <div v-text="form.formType"></div>
          </el-form-item>
          <el-form-item v-else label="表单类型"  :rules="[{ required: true, message: '请输入表单类型', trigger: 'change' }]" prop="formType" :style="{ marginBottom: '20px' }">
            <v-input v-model="form.formType" placeholder="请输入" :width="width"/>
          </el-form-item>

          <el-form-item label="流程标识" :rules="[{ required: true, message: '请选择流程标识', trigger: 'change' }]" prop="processKey">
            <v-select v-model="form.processKey" :options="typeOps" />
          </el-form-item>

          <el-form-item label="数据库实体表" :rules="[{ required: true, message: '请输入数据库实体表', trigger: 'change' }]" prop="businessTable">
            <v-input v-model="form.businessTable" placeholder="请输入数据库实体表名" :width="width" />
          </el-form-item>

        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryURL, createURL, updateURL, getTypeListURL, getProcessByKeyURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'

export default {
  name: 'FormProcessRelavDetail',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {

    return {
      width: 200,
      isLoad: false,
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: undefined,
        submitMethod: ''
      },
      typeOps: [
          {
              text: '请选择',
              value: undefined
          },
          {
              text: '请假',
              value: 'take-off'
          },
          {
              text: '报事报修',
              value: 'repair'
          }
      ],
      form: {
        id: undefined,
        formType: '',
        processKey: undefined,
        businessTable: ''
      },
      
    }
  },
  created () {
    this.getTypeList()
  },
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.form.id = id
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.submitMethod = 'POST'
      this.$refs.formPanel.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
      this.submitConfig.submitMethod = 'POST'
    }
    this.isLoad = true
  },
  methods: {
    getTypeList () {
        this.$axios.get(getProcessByKeyURL).then(res=>{
          console.log(res)
          this.typeOps = []
          this.typeOps.push({
             text: '请选择',
             value: undefined
          })
          if(res.status === 100) {
            
            res.data.dataList.forEach(element => {
              this.typeOps.push({
                text : element.name,
                value: element.processKey
              })
            });
          }
        })
    },
    update (data) {
      this.form.formType = data.formType
      this.form.businessTable = data.businessTable
      this.form.processKey = data.processKey
    },
    submitBefore (data) {
        return data
      
    }
  }
}
</script>

<style lang="scss">
.sensitive-word-form {
  .el-form-item__error {
    white-space: nowrap;
  }
}
</style>
<style lang="scss" scoped>
.sensitive-word-form {
  .tips {
    margin-left: 20px;
  }
}
</style>
