var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoad,
          expression: "isLoad",
        },
      ],
      staticClass: "fromProcess-relav-form",
    },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  this.form.id !== undefined
                    ? _c("el-form-item", { attrs: { label: "表单类型" } }, [
                        _c("div", {
                          domProps: { textContent: _vm._s(_vm.form.formType) },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          style: { marginBottom: "20px" },
                          attrs: {
                            label: "表单类型",
                            rules: [
                              {
                                required: true,
                                message: "请输入表单类型",
                                trigger: "change",
                              },
                            ],
                            prop: "formType",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { placeholder: "请输入", width: _vm.width },
                            model: {
                              value: _vm.form.formType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "formType", $$v)
                              },
                              expression: "form.formType",
                            },
                          }),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "流程标识",
                        rules: [
                          {
                            required: true,
                            message: "请选择流程标识",
                            trigger: "change",
                          },
                        ],
                        prop: "processKey",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.typeOps },
                        model: {
                          value: _vm.form.processKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "processKey", $$v)
                          },
                          expression: "form.processKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数据库实体表",
                        rules: [
                          {
                            required: true,
                            message: "请输入数据库实体表",
                            trigger: "change",
                          },
                        ],
                        prop: "businessTable",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入数据库实体表名",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.businessTable,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "businessTable", $$v)
                          },
                          expression: "form.businessTable",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }